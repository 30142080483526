import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/Navbar.css';
import { Corporate_url } from '../../Config/Config';

const Navbar = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const[userId,setUserId]=useState('');
  const fetchUserDetails = async () => {
    try {
      const token = localStorage.getItem('token');
      // const userId = localStorage.getItem('user_id');

      if (userId && token) {
        const response = await axios.get(`${Corporate_url}/api/user-details/${Number(userId)}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setUserDetails(response.data);
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(()=>{
      setUserId(localStorage.getItem('user_id'));
    },1000)
    fetchUserDetails();
  }, [userId]); // Only run once on mount

  if (loading) {
    return <div>Loading...</div>; // Optional: Show a loading indicator
  }

  return (
    <div className='banner-div d-flex align-items-center justify-content-between'>
      <div className="main-logo-section">
        <a href="/home/TU0000">
          <img src='/images/polus.webp' alt="Logo" className="logo" />
        </a>
      </div>
      <div className='banner-title'>
        <span>SecretPass</span>
      </div>
      <div className='profile-label-section align-items-center'>
        {userDetails ? (
          <div className="user-profile d-flex align-items-center">
            <div className='profile-image1'>
              <img height={'20px'} width={'100%'}
                src={userDetails.profile_pic || "/images/profile-user.png"} 
                alt="Profile Pic" 
                className='profile-pic' 
              />
            </div>
            <div className="user-info">
              <span className="user-name">{`${userDetails.first_name || 'Guest'} ${userDetails.last_name || ''}`}</span>
              <span className="user-role">{userDetails.access_type === "1" ? "Admin" : "Employee"}</span>
            </div>
          </div>
        ) : (
          <div className="user-profile d-flex align-items-center">
            <div className='profile-image'>
              <img height={'50px'} width={'100%'} src="/images/profile-user.png" alt="Profile Pic" className='profile-pic' />
            </div>
            <div className="user-info">
              <span className="user-name">Guest</span>
              <span className="user-role">Visitor</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
