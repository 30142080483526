import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import api from '../../Api/api';
import { encryptMessage } from '../../Utils/EncryptionDecryption'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/request.css';
import baseUrl from '../../Config/Config';
import { Row, Spinner } from 'react-bootstrap';
import {  ThreeDots } from 'react-loader-spinner';

const userTypes = [
  { value: 'os_user', label: 'OS User' },
  { value: 'system_user', label: 'System User' },
  { value: 'technical_user', label: 'Technical User' },
  { value: 'db_user', label: 'DB User' },
  {value:"others", label:"Others"},
  
];

const Request = () => {
  const [validated, setValidated] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedFields, setSelectedFields] = useState([]); 
  const [message, setMessage] = useState('');
  const [customer, setCustomer] = useState('');
  const [platform, setPlatform] = useState('');
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const [userType, setUserType] = useState('');
  const [userId, setUserId] = useState(''); // Keep userId as empty or set initial value
  const [password, setPassword] = useState(''); // Keep password as empty or set initial value

  useEffect(() => {
    const fetchEmployees = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${baseUrl}/api/getallusers/`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch employees');
        }

        const data = await response.json();
        setEmployees(data);
      } catch (error) {
        console.error('Error fetching employee data:', error);
        toast.error('Failed to fetch employees');
      } finally {
        setLoading(false);
      }
    };

    fetchEmployees();
  }, []);

  const optionsData = employees.map(emp => ({
    value: emp.employeeId,
    label: `${emp.first_name} ${emp.last_name} (${emp.employeeId})`,
  }));

  const handleSelectPersonChange = (data) => {
    setSelectedOption(data);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    setValidated(true);
    const toastId = toast.loading('Requesting...');
    setIsSubmitting(true);

    
    try {
      const token = localStorage.getItem('token');
      const employeeId = localStorage.getItem('user_id');
      const encryptedMessage = encryptMessage(message);
      const encryptedCustomer = encryptMessage(customer);
      const encryptedPlatform = encryptMessage(platform);
      const encryptedUserId = encryptMessage(userId);
      const encryptedPassword = encryptMessage(password);
      const requestBody = {
        message: encryptedMessage,
        customer: encryptedCustomer,
        platform: encryptedPlatform,
        inputType: selectedFields.map(field => field.value),
        sender: employeeId,
        recipient: selectedOption?.value,
        userType,
        userId: encryptedUserId,
        password: encryptedPassword,
      };
      const response = await api.post(
        `${baseUrl}/api/request-password/requestpass`,
        requestBody,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      toast.dismiss(toastId);

      if (response.status === 201) {
        toast.success('Request sent successfully!');
        setMessage('');
        setCustomer('');
        setPlatform('');
        setUserId(''); // Retain this if needed
        setPassword(''); // Retain this if needed
        setSelectedOption(null);
        setSelectedFields([]);
        setUserType('');
      } else {
        toast.error('Failed to send request');
      }
    } catch (error) {
      toast.dismiss(toastId);
      console.error('Error sending request:', error.message);
      toast.error('Error sending request');
    } finally {
      setIsSubmitting(false);
    }
  };



  return (
    <div className=' typeofUser mt-5 p-2'>
      <div className='mt-5'></div>
      <div className='p-form'>
        <div className="c-form">  
        <div className="r-box mt-5 p-2">
        <h4 className=" text-center ">Request Password</h4><br /><br />
        {loading ? (
          <div className="d-flex justify-content-center mt-5">
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="rgb(91, 48, 229)"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
              />
           
          </div>
        ) : (
          <div className='request-from'>
          <form noValidate validated={validated.toString()} onSubmit={handleSubmit}>
            <Row>
              {/* <div className="col-1"></div> */}
              <div className="mb-4 p-type text-start col-12">
                <label className="label-form">Type of User <span style={{ color: 'red' }}> * </span>:</label>
                <div id="userType" className=" user-type p-2">
                  {userTypes.map(option => (
                    <div key={option.value} className="radio-box-r">
                      <input
                        required
                        type="radio" 
                        id={option.value} 
                        name="userType" 
                        value={option.value} 
                        checked={userType === option.value}
                        onChange={() => setUserType(option.value)} 
                      />
                      <label className='text-nowrap p-2' htmlFor={option.value}>{option.label}</label>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="col-1"></div> */}
            </Row>
            <Row>

              {/* <div className='col-1'></div> */}
              <div className="mb-3 col-6">
                <label htmlFor="customerInput">Customer <span style={{ color: 'red' }}> * </span>:</label>
                <input
                 autoComplete="off" 
                  type="text"
                  id="customerInput"
                  placeholder="Enter Customer Name"
                  value={customer}
                  onChange={(e) => setCustomer(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3 col-6">
                <label htmlFor="platformInput">System/Platform/Server<span style={{ color: 'red' }}> * </span>:</label>
                <input
                 autoComplete="off" 
                  type="text"
                  id="platformInput"
                  placeholder="Enter System/Platform/Server Name"
                  value={platform}
                  onChange={(e) => setPlatform(e.target.value)}
                  required
                />
              </div>
              {/* <div className='col-1'></div> */}
            </Row>
            <Row>
              {/* <div className='col-1'></div> */}
              <div className="mb-3 col-6">
                <div><label htmlFor="userIdInput">User:</label></div> 
                <input
                  autoComplete="off" 
                  type="text"
                  id="userIdInput"
                  placeholder=""
                  value={userId} // Retain value
                  onChange={(e) => setUserId(e.target.value)} // Allow user to change value if needed
                />
              </div>
              <div className="mb-3 col-6">
                <label htmlFor="passwordInput">Password :</label>
                <input 
                  autoComplete="off" 
                  type="password"
                  id="passwordInput"
                  placeholder=""
                  value={password} // Retain value
                  onChange={(e) => setPassword(e.target.value)} // Allow user to change value if needed
                  disabled // Locking field
                />
              </div>
              {/* <div className='col-1'></div> */}
            </Row>
            <Row>
              {/* <div className='col-1'></div> */}
              <div className="mb-3 col-12">
                <label htmlFor="employeeSelect">Select Employee<span style={{ color: 'red' }}> * </span>:</label>
                <Select 
                    id="employeeSelect"
                    isClearable={true}
                    value={selectedOption}
                    onChange={handleSelectPersonChange} 
                    options={optionsData}
                    styles={{
                        control: (base, state) => ({
                          ...base,
                          borderColor: state.isFocused ?'rgb(228, 219, 255)' : '#f7f4ff',  // Focused border color
                          boxShadow: state.isFocused ? '0 0 0 1px rgb(228, 219, 255)' : 'none', // Optional: add shadow on focus
                          '&:hover': {
                              borderColor: 'rgb(247 244 255)', // Change border color on hover
                          },
                        }),
                        multiValue: (base) => ({
                            ...base,
                            backgroundColor: '#ccbdff'
                        }),
                        multiValueLabel: (base) => ({
                            ...base,
                            color: 'black'
                        }),
                        option: (base, state) => ({
                          ...base,
                          backgroundColor: state.isFocused ? '#d9caff' : 'white', // Highlight on hover
                          color: state.isSelected ? 'white' : 'black',
                          cursor: 'pointer', // Change cursor to pointer
                          padding: '10px', // Add padding
                      }),
                    }}
                />

              </div>
              {/* <div className='col-1'></div> */}
            </Row>
            <Row>
              {/* <div className="col-1"></div> */}
              <div className="mb-3 col-msg col-12">
                <label htmlFor="messageInput">Message :</label>
                <textarea
                  autoComplete="off" 
                  id="messageInput"
                  rows={1}
                  placeholder="Type your request message..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                  className='w-100 p-3'
                />
              </div>
              {/* <div className="col-1"></div> */}
            </Row>
            
            <Row>
              {/* <div className='col-1'></div> */}
              <div className="d-flex justify-content-end col-12">
                <button className="f-btn" type="submit" disabled={isSubmitting}>Request Password</button>
              </div>
              {/* <div className="col-1"></div> */}
            </Row>
          </form>
              

          </div>
        )}
      </div>
      </div>
      </div>
    
      <ToastContainer 
        position="top-center"
        autoClose={1000} 
        hideProgressBar={true}
        closeOnClick
      />
    </div>
  );
};

export default Request;
