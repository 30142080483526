// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-section-maintenance {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    min-height: 90vh;
    /* padding: 30px 40px; */
    background: #fff;
    width: 100%;
    margin-top: -78px;
}

.maintenance-mode-main {
    padding: 0px 0px 50px 0px;
}

.maintenance-mode-content {
    text-align: center;
}

h3.maintenance-mode-title {
    font-size: 36px;
    font-weight: 600;
    color: #fd7c0d;
}

.maintenance-body-content {
    display: flex;
    flex-direction: column;
}

span.maintenance-mode-timer {
    font-size: 24px;
    font-weight: 400;
    padding-bottom: 20px;
}

img.maintenance-image {
    width: 100%;
    height: auto;
}`, "",{"version":3,"sources":["webpack://./src/styles/maintenance/maintenance.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;IACT,gBAAgB;IAChB,wBAAwB;IACxB,gBAAgB;IAChB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".content-section-maintenance {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 20px;\n    min-height: 90vh;\n    /* padding: 30px 40px; */\n    background: #fff;\n    width: 100%;\n    margin-top: -78px;\n}\n\n.maintenance-mode-main {\n    padding: 0px 0px 50px 0px;\n}\n\n.maintenance-mode-content {\n    text-align: center;\n}\n\nh3.maintenance-mode-title {\n    font-size: 36px;\n    font-weight: 600;\n    color: #fd7c0d;\n}\n\n.maintenance-body-content {\n    display: flex;\n    flex-direction: column;\n}\n\nspan.maintenance-mode-timer {\n    font-size: 24px;\n    font-weight: 400;\n    padding-bottom: 20px;\n}\n\nimg.maintenance-image {\n    width: 100%;\n    height: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
