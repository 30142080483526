
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../Api/api';
import baseUrl from '../../Config/Config';
import { decryptMessage } from '../../Utils/EncryptionDecryption';
import '../../styles/inbox.css';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { faXmark, faSearch} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from 'react-bootstrap';
import { ThreeDots } from 'react-loader-spinner';
import Messagenotfound from '../RecieveMessages/Assets/no message.png'

const Inbox = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [requestedPasswords, setRequestedPasswords] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [loadingRequests, setLoadingRequests] = useState(true);
  const [loading, setLoading] = useState({ messages: true, requests: true }); // Consolidated loading states
  const [viewCounts, setViewCounts] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState('inbox');
  const [errorPassword, setErrorPassword] = useState(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [showHideButton, setShowHideButton] = useState(false);
  const [error, setError] = useState({ messages: null, passwords: null });
  const [transitionState, setTransitionState] = useState('enter'); // For transition animation
  const filteredMessages = messages.filter(msg => 
    !msg.hidden && !msg.expired && (
        msg.senderInfo?.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        msg.senderInfo?.last_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
);
  const filteredRequests = requestedPasswords.filter(req =>
    req.senderInfo?.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    req.senderInfo?.last_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  const inboxCount = filteredMessages.length;
  const requestsCount = filteredRequests.filter(req => !req.isReplied).length;
  
  const otherMessages = messages.filter(msg => !filteredMessages.includes(msg));
  const otherRequests = requestedPasswords.filter(req => !filteredRequests.includes(req));

  useEffect(() => {
    const fetchData = async () => {
      setLoading({ messages: true, requests: true });
      const token = localStorage.getItem('token');
      if (!token) {
        navigate("/login");
        return;
      }

      try {
        const [messagesResponse, requestsResponse] = await Promise.all([
          api.get(`${baseUrl}/api/messages/received-messages/${id}`, {
              headers: { 'Authorization': `Bearer ${token}` },
          }),
          api.get(`${baseUrl}/api/request-password/request/${id}`, {
              headers: { 'Authorization': `Bearer ${token}` },
          }),
      ]);

      const currentTime = new Date();
      const removedMessageIds = JSON.parse(localStorage.getItem('removedMessageIds')) || [];

      if (messagesResponse.data && Array.isArray(messagesResponse.data.messages)) {
          const decryptedMessages = messagesResponse.data.messages
              .map(msg => ({
                  ...msg,
                  decryptedMessage: decryptMessage(msg.encryptedMessage),
                  decryptedPassword: decryptMessage(msg.encryptedPassword),
                  decryptedUserId: decryptMessage(msg.userId),
              }))
              .filter(msg => {
                return !removedMessageIds.includes(msg._id) && !msg.expired; // Exclude expired messages
              });

          decryptedMessages.sort((a, b) => new Date(b.sentAt) - new Date(a.sentAt));
          setMessages(decryptedMessages);
          setViewCounts(JSON.parse(localStorage.getItem('viewCounts')) || {});
      } else {
          setError('No messages found.');
      }

      if (requestsResponse.data && Array.isArray(requestsResponse.data.requests)) {
          const requestedData = requestsResponse.data.requests.map(request => ({
              ...request,
              message: decryptMessage(request.message),
              customer: decryptMessage(request.customer),
              platform: decryptMessage(request.platform),
              senderInfo: request.senderInfo,
              decryptedUserId: decryptMessage(request.userId),
          }));
          requestedData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          setRequestedPasswords(requestedData);
      } else {
          setErrorPassword('No requested passwords found.');
      }
    } catch (err) {
      const errMsg = err.response?.data?.message || 'An error occurred while fetching data.';
      setError(prev => ({ ...prev, messages: errMsg, passwords: errMsg }));
    } finally {
      setLoading({ messages: false, requests: false }); // Stop loading for both states
    }
  };

    fetchData();
  }, [id, navigate]);

 
  const handleShowPassword = () => {
    setIsPasswordVisible(true);
    setShowHideButton(true); // Show the back button
  
    setTimeout(() => {
      setIsPasswordVisible(false);
      setShowHideButton(false); // Hide the back button after 10 seconds
    }, 10000); // 10 seconds
  };

  const handleShowMessageDetails = async (message) => {
    setSelectedMessage(message);
    setSelectedRequest(null);
    setShowDetails(true);
    await updateViewCounts(message); // Update view count when showing details
};

const removeMessage = (id) => {
    setMessages(prevMessages => {
        const updatedMessages = prevMessages.filter(msg => msg._id !== id);

        // Clear selected message if necessary
        if (selectedMessage?._id === id) {
            setSelectedMessage(null);
            setShowDetails(false);
        }

        // Update local storage
        const removedMessageIds = JSON.parse(localStorage.getItem('removedMessageIds')) || [];
        if (!removedMessageIds.includes(id)) {
            removedMessageIds.push(id);
            localStorage.setItem('removedMessageIds', JSON.stringify(removedMessageIds));
        }

        return updatedMessages;
    });
};

const updateViewCounts = (message) => {
  setViewCounts(prevCounts => {
      const newCount = (prevCounts[message._id] || 0) + 1;
      const updatedCounts = { ...prevCounts, [message._id]: newCount };
      localStorage.setItem('viewCounts', JSON.stringify(updatedCounts));

      // Immediately mark the message as hidden if it's expired
      if (newCount >= 2) {
        setMessages(prevMessages => 
            prevMessages.map(msg => 
                msg._id === message._id ? { ...msg, expired: true } : msg
            )
        );
    }

      return updatedCounts; // Return updated counts for state
  });

  // Make the API call to update the backend
  api.post(`${baseUrl}/api/messages/update-view-counts`, { messageId: message._id })
      .then(response => {
          // Handle expiration if necessary
          if (response.data.expired) {
              setMessages(prevMessages => 
                  prevMessages.filter(msg => msg._id !== message._id)
              );
          }
      })
      .catch(error => console.error('Failed to update view counts:', error));
};

  const handleShowRequestDetails = (request) => {
    setSelectedRequest(request);
    setSelectedMessage(null);
    setShowDetails(true);
  };

  const handleReply = (request) => {
    if (request.isReplied) {
      alert('Password already shared.');
      return; // Stop execution if already replied
    }
    navigate(`/sendMessages/${id}`, {
      state: {
        request_messageId:request._id,
        reciever_userId: request.senderInfo.employeeId,
        reciever_employeeName: `${request.senderInfo.first_name} ${request.senderInfo.last_name}`,
        reciever_profilePic: request.senderInfo.profile_pic || '/images/profile-user.png',
        reciever_role: request.senderInfo.role,
        reciever_customer: request.customer,
        reciever_platform: request.platform,
        reciever_userType: request.userType,
        reciever_user: request.userId,
      },
      
    });


    
  };

  const handleCloseDetails = () => {
    setTransitionState('exit'); // Prepare for exit transition
    setTimeout(() => {
      setShowDetails(false);
      setSelectedMessage(null);
      setSelectedRequest(null);
      setTransitionState('enter'); // Reset for next entry
    }, 300); // Match this duration to your CSS transition duration
  };

  const renderMessages = () => {
    if (loading.messages) {
      return <ThreeDots
      visible={true}
      height="100"
      width="100"
      color="rgb(91, 48, 229)"
      radius="9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClass=""
      />
    }
    if (error.messages) {
      return <div className='text-danger'>{error.messages}</div>;
    }
  
    
    return (
      <ol style={{ listStyleType: 'none', padding: 0 }} className='ol-group'>
        {filteredMessages.length > 0 ? (
          filteredMessages.map(message => (
            <li 
              key={message._id} 
              className={`list-group ${selectedMessage?._id === message._id ? 'selected' : ''}`}
              onClick={() => handleShowMessageDetails(message)} 
            >
              <div className='list-profile-details'>
                <img src={message.senderInfo?.profile_pic} alt="dp" />
                <div className='d-flex w-100'>
                  <p className='name-list'>
                    {message.senderInfo ? `${message.senderInfo.first_name} ${message.senderInfo.last_name}` : 'Unknown Sender'}
                  </p>
                  <div style={{width: '94%'}}>
                  <p className='time d-flex justify-content-end w-100'>{new Date(message.sentAt).toLocaleTimeString()}</p>
                  </div>
                </div>
              </div>
            </li>
          ))
        ) : (
          <div className='no-message-found'>
            <li className='text-center fw-bolder'>
          <img src={Messagenotfound} alt="No messages found"  />
          <br />
          <p className='p-2'>No messages found.</p>
        </li>
          </div>
         
        )}
        {otherMessages.map(message => (
          <li 
            key={message._id} 
            className={`list-group ${selectedMessage?._id === message._id ? 'selected' : ''}`}
            onClick={() => handleShowMessageDetails(message)} 
          >
            <div className='list-profile-details'>
              <img src={message.senderInfo?.profile_pic} alt="dp" />
              <div className='d-flex w-100'>
                <p className='name-list d-flex justify-content-start w-25'>
                  {message.senderInfo ? `${message.senderInfo.first_name} ${message.senderInfo.last_name}` : 'Unknown Sender'}
                </p>
              </div>
            </div>
          </li>
        ))}
      </ol>
    );
  };

  const renderRequestedPasswords = () => {
    if (loading.requests) {
      return <ThreeDots
      visible={true}
      height="100"
      width="100"
      color="rgb(91, 48, 229)"
      radius="9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClass=""
      />
    }

    if (error.passwords) {
      return <div className='text-danger'>{error.passwords}</div>;
    }
   

    return (
      <div className='parent-list'>
        <br />
        <ol style={{ listStyleType: 'none', padding: 0 }} className='ol-group'>
          {filteredRequests.length > 0 ? (
           filteredRequests.map(request => (
              <li 
                key={request._id} 
                className={`list-group mt-2 ${selectedRequest?._id === request._id ? 'selected' : ''}`}
                onClick={() => handleShowRequestDetails(request)} 
              >
                <div className='list-profile-details'>
                  <img src={request.senderInfo?.profile_pic} alt="dp" />
                  <div className='d-flex w-100'>
                    <p className='name-list d-flex justify-content-start w-25'>
                      {request.senderInfo ? `${request.senderInfo.first_name} ${request.senderInfo.last_name}` : 'Unknown Sender'}
                    </p>
                    <p className='time d-flex justify-content-end'>
                      {new Date(request.createdAt).toLocaleTimeString()}
                    </p>
                  </div>
                </div>
              </li>
            ))
          ) : (
            <div className='no-message-found'>
            <li className='text-center fw-bolder'>
          <img src={Messagenotfound} alt="No messages found"  />
          <p>No requested passwords found</p>
        </li>
          </div>
          )}
          {otherRequests.map(request => (
            <li 
              key={request._id} 
              className={`list-group ${selectedRequest?._id === request._id ? 'selected' : ''}`}
              onClick={() => handleShowRequestDetails(request)} 
            >
              <div className='list-profile-details'>
                <img src={request.senderInfo?.profile_pic} alt="dp" />
                <div className='flex-grow-1'>
                  <p className='fw-bold mt-3'>
                    {request.senderInfo ? `${request.senderInfo.first_name} ${request.senderInfo.last_name}` : 'Unknown Sender'}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ol>
        {/* {renderPagination()} */}
      </div>
    );
  };

const handleTabClick = (tab) => {
  setActiveTab(tab);
  
  // Close message details when switching tabs
  setShowDetails(false); 
  setSelectedMessage(null); // Optionally clear the selected message as well
  setSelectedRequest(null); // Optionally clear the selected request as well
};
  return (
    <div className="">
            <div className='mt-4 main'>
      <Row>
        <div className='p-main d-flex w-50 justify-content-start col-6'>
          <h4 className='w-50 d-flex justify-content-start'>Messages</h4>
        </div>
        {showDetails && (selectedMessage || selectedRequest) && (
         <div style={{ width: '50%', borderLeft: '1px solid rgb(228 219 255)' }}
          className='d-flex col-6'>
            <div className=' head w-25'>
              <h4 className='msg-head'>Details</h4>
            </div>
                 <div className='b-btn d-flex  w-100 justify-content-end'>
                  <button onClick={handleCloseDetails}>
                    <FontAwesomeIcon icon={faXmark} size='l' style={{ color: "rgb(110, 98, 229)", height: "30px" }} />
                  </button>
                </div>
          </div>

        )}
      </Row>

      <Row>
      <div className="custom-tabs w-50 mx-3">
          <button 
            className={`tab-button ${activeTab === 'inbox' ? 'active' : ''}`} 
            onClick={() => handleTabClick('inbox')}
            style={{ borderLeft: "none" }}
          >
            Inbox {inboxCount > 0 && <span className="badge">{inboxCount}</span>}
          </button>
          <button 
            className={`tab-button ${activeTab === 'requested-passwords' ? 'active' : ''}`} 
            onClick={() => handleTabClick('requested-passwords')}
          >
            Incoming Requests {requestsCount > 0 && <span className="badge">{requestsCount}</span>}
          </button>
          </div>
           <br /><br />

        <div className="d-flex">
          <br />

          <div className={`content ${showDetails ? 'col-6' : 'col-12'}`}>
          <div className='search d-flex align-items-center'>
              <FontAwesomeIcon icon={faSearch} className='search-icon' />
              <input
                type="text"
                placeholder={searchTerm ? "" : "Search..."}  
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                className="search-form"
              />
            </div>
            <br />
            {activeTab === 'inbox' ? renderMessages() : renderRequestedPasswords()}
          </div>

          {showDetails && (selectedMessage || selectedRequest) && (
            <div className='content-details w-100'>
              <div className='w-100' style={{ borderBottom: "1px solid rgb(214, 214, 214)" }}></div>
              <div className='details-container w-100  ms-show'> 

                {(selectedMessage && (
                  <>
                    <div className='msgBar d-flex'>
                      <div className='msg-profile'>
                        <img src={selectedMessage.senderInfo?.profile_pic} alt="dp" />
                      </div>
                      <div className='text '>
                        {selectedMessage.senderInfo?.first_name} {selectedMessage.senderInfo?.last_name}
                        <div>
                          <p className='date-1 w-100 text-end'>
                            <span></span>Sent at {new Date(selectedMessage.sentAt).toLocaleTimeString()}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='p-5 msg-text'>
                    <div className='msg-border'>
                    <div className='box-m w-100'>
                        <p className='main-msg'>Customer: <br />
                        <span className='request-input'>{selectedMessage.customer}</span> </p>
                        <p className='main-msg'>Platform: <br />
                        <span className='request-input'>{selectedMessage.platform}</span> </p>
                        <p className='main-msg'>Type: <br />
                         <span className='request-input'> {selectedMessage.type}</span></p>
                        <p className='main-msg'>User: <br />
                         <span className='request-input'> {selectedMessage.decryptedUserId}</span></p>
                        <p className='main-msg'>Message: <br />
                         <span className='request-input'> {selectedMessage.decryptedMessage}</span></p>
                          <div>
                          <p className='main-msg'>
                            Password: <br />
                            <div className='eye-button'>
                            <div className='eye-button'>
                              <span className='request-input eye-button-2'>
                                {isPasswordVisible ? selectedMessage.decryptedPassword : '******'}
                                {showHideButton ? (
                                  <button onClick={() => {
                                    setIsPasswordVisible(false); 
                                    setShowHideButton(false); // Also hide the back button
                                  }} className='show-password-btn'>
                                    <FaEyeSlash style={{ color: 'rgb(98 118 140)', cursor: 'pointer', fontSize: '1.5rem' }} />
                                  </button>
                                ) : (
                                  <button onClick={handleShowPassword} className='show-password-btn' disabled={isPasswordVisible}>
                                    <FaEye style={{ color: 'rgb(98 118 140)', cursor: 'pointer', fontSize: '1.5rem' }} />
                                  </button>
                                )}
                              </span>
                            </div>
                            </div>  
                          </p>
                          </div>

                      </div>
                    </div>
                    </div>
                  </>
                )) || (selectedRequest && (
                  <>
                    <div className='msgBar d-flex'>
                      <div className='msg-profile'>
                        <img src={selectedRequest.senderInfo?.profile_pic} alt="dp" />
                      </div>
                      <div className='text'>
                        {selectedRequest.senderInfo ? `${selectedRequest.senderInfo.first_name} ${selectedRequest.senderInfo.last_name}` : 'Unknown Sender'}
                        <div><br />
                          <p className='date w-100 text-end'>
                            <span>Requested at </span>{new Date(selectedRequest.createdAt).toLocaleTimeString()}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='msg-text p-4'>
                    <div className='msg-border'>
                      <div className='box-m'>
                        <p className='main-msg'>Customer: <br /><span className='request-input'> {selectedRequest.customer}</span> </p>
                        <p className='main-msg'>Platform:<br /><span className='request-input'>{selectedRequest.platform}</span> </p>
                        <p className='main-msg'>UserType: <br /><span className='request-input'>{selectedRequest.userType}</span> </p>
                        {selectedRequest.decryptedUserId && (
                          <div className='need'>
                            <p className='main-msg'>User: <br /><span className='request-input'>  {selectedRequest.decryptedUserId}</span></p>
                          </div>
                        )}
                        
                      </div>
                      <Row>
                        <div className='col-1'></div>
                        <div className="col-10 msg"><p className='main-msg text-start'>Message: <span className='request-input'> {selectedRequest.message}</span></p></div>
                        <div className="col-1"></div>
                      </Row>
                     
                      <div className='p-btn d-flex justify-content-end'>
                      <button 
                              className={`m-btn ${selectedRequest.isReplied ? 'btn-done btn-replyDone' : 'btn-replyDone btn-reply'}`} 
                              onClick={() => handleReply(selectedRequest)}
                            >
                              {selectedRequest.isReplied ? 'Done' : 'Reply'}
                            </button>
                         </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          )}
        </div>
      </Row>
    </div>
    </div>

  );
};

export default Inbox;
