import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'; 
import Navbar from './Components/Navbar/Navbar';
import Login from './Pages/Login/Login';
import Sidebar from './Components/Sidebar/Sidebar';
import Home from './Pages/Home/Home';
import Inbox from './Pages/RecieveMessages/Inbox';
import SendMessages from './Pages/SendMessages/SendMessages';
import Request from './Pages/Request/Request';
import './styles/style.css'
import MaintenanceMode from "./Pages/MaintenanceMode/MaintenanceMode"; 
import { Corporate_url } from '../src/Config/Config';
import api from '../src/Api/api';


// import { corporateUrl } from './config/config';

function App() { 
  const [isAdmin, setIsAdmin] = useState(true);
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("authenticated") === "true" // Check localStorage for authentication
  );

  const [maintenanceStatus, setMaintenanceStatus] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [activeIndex,setActiveIndex] = useState(1);

  // Maintenance check on load/refresh
  useEffect(() => {
    const fetchMaintenanceDetails = async () => {
      const tool = 'secretpass';
      const accessToken = localStorage.getItem('token');
      if (!accessToken) {
        console.warn('Access token is missing, skipping maintenance check.');
        return;
      }

      try {
        const response = await api.get(`${Corporate_url}/api/get-maintenance-details/${tool}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });
        
        const isMaintenanceActive = response.data.maintenanceStatus || false;
        setMaintenanceStatus(isMaintenanceActive);

        if (isMaintenanceActive && !location.pathname.includes("/maintenance-mode")) {
          navigate(`/maintenance-mode/${tool}`);
        }
      } catch (error) {
        console.error("Failed to fetch maintenance details:", error);
        setMaintenanceStatus(false);
      }
    };

    fetchMaintenanceDetails();
  }, [navigate, location.pathname]);

  // On maintenance end, redirect to home page if refreshed or on maintenance page
  useEffect(() => {
    // Extract the toolName from the URL path
    const toolName = location.pathname.split("/maintenance-mode/")[1];

    if (!maintenanceStatus && location.pathname.includes("/maintenance-mode")) {
      // If toolName is "corporateportal", redirect to home or login
      if (toolName === 'secretpass') {
        const userId = localStorage.getItem("user_id");
        if (userId) {
          navigate(`/home/${userId}`);
        } else {
          navigate("/login");
        }
      }
      // Otherwise, do not redirect, allow for other tools
    }
  }, [maintenanceStatus, location.pathname, navigate]);

  return (
      <div className="App"> 
        <div className="main-content">
          <div className="body-section">
            <div className="d-flex flex-column flex-xl-row w-100">
            {authenticated &&  !location.pathname.includes("/maintenance-mode") && ( 
              <div className="sidebar-section min-vh-0">
                <Sidebar activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
              </div>
              )}
              {!location.pathname.includes("/maintenance-mode") && (
                <Navbar /> 
              )}
              <Routes> 
                <Route path="/" element={<Home/>} />
                <Route path="/home/:id" element={<Home />} />
                <Route path="/sendMessages/:id" element={<SendMessages />} />
                <Route path="/inbox/:id" element={<Inbox />} />
                <Route path="/requestMessage/:id" element={<Request />} />
                <Route
                path="/login"
                element={<Login setAuthenticated={setAuthenticated} />}
                />
                <Route path="/maintenance-mode/:tool" element={<MaintenanceMode />} />
              </Routes>
            </div>
          </div>
        </div>
      </div> 
  );
}

export default App;
