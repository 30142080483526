import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../../styles/maintenance/maintenance.css';
import { Corporate_url } from '../../Config/Config';
import api from '../../Api/api';

const MaintenanceMode = () => {
  const [statusDetails, setStatusDetails] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState('');
  const [maintenanceEndTime, setMaintenanceEndTime] = useState('');
  const navigate = useNavigate();
  const { tool } = useParams();

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          navigate("/login");
        } else {
          const response = await api.get(`${Corporate_url}/api/get-maintenance-details/${tool}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          setStatusDetails(response.data);
        }
      } catch (error) {
        console.error('Error fetching details:', error);
      }
    };

    fetchDetails();
  }, [tool, navigate]);

  useEffect(() => {
    if (statusDetails) {
      const { createDate, duration } = statusDetails;

      // Convert createDate to Date object
      const maintenanceStart = new Date(createDate);

      // Calculate maintenance end time by adding duration (in hours)
      const maintenanceEnd = new Date(maintenanceStart.getTime() + duration * 60 * 60 * 1000);

      // Format the maintenance end time in a readable way
      const formattedEndTime = maintenanceEnd.toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
      });

      setMaintenanceEndTime(formattedEndTime);

      const calculateTimeRemaining = () => {
        const now = new Date();
        const totalDurationInMs = duration * 60 * 60 * 1000; // Convert duration to milliseconds
        const timeElapsed = now - maintenanceStart; // Calculate time elapsed since start
        const timeRemainingInMs = totalDurationInMs - timeElapsed;

        if (timeRemainingInMs > 0) {
          const hours = Math.floor(timeRemainingInMs / (1000 * 60 * 60));
          const minutes = Math.floor((timeRemainingInMs % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeRemainingInMs % (1000 * 60)) / 1000);

          setTimeRemaining(
            `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
          );
        } else {
          setTimeRemaining('00:00:00');
        }
      };

      const interval = setInterval(calculateTimeRemaining, 1000);
      calculateTimeRemaining(); // Initial calculation

      return () => clearInterval(interval);
    }
  }, [statusDetails]);

  return (
    <div className='content-section-maintenance flex-wrap'>
      <div className='maintenance-mode-main'>
        <div className='maintenance-mode-image-section'>
          <img src="/images/maintenace2.gif" className='maintenance-image' alt="Maintenance" />
        </div>
        <div className='maintenance-mode-content'>
          <div>
            <h3 className='maintenance-mode-title'>Sorry! Under Maintenance</h3>
          </div>
          <div className='maintenance-body-content'>
            {statusDetails ? (
              <>
                <span> We're currently down for maintenance for {statusDetails.duration} hours, </span>
                <span> but don't worry, we'll be back up in {maintenanceEndTime}. </span>
                <br />
                <span className='maintenance-mode-timer'> {timeRemaining}</span>
                {statusDetails.toolName === "corporateportal" ? 
                  <span>You can access the service tracker using this link: <a href="https://servicetracker.fibiweb.com/fibi-sr">Service Tracker</a>.</span>
                :
                ''
                }
                
              </>
            ) : (
              <span>Unable to load maintenance details at this moment. Please try again later.</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceMode;


