import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import Navbar from './Components/Navbar/Navbar';
import Login from './Pages/Login/Login';
import Sidebar from './Components/Sidebar/Sidebar';
import Home from './Pages/Home/Home';
import Inbox from './Pages/RecieveMessages/Inbox';
import SendMessages from './Pages/SendMessages/SendMessages';
import Request from './Pages/Request/Request';
import './styles/style.css'
import './App.css'



// import { corporateUrl } from './config/config';

function App() { 
  const [isAdmin, setIsAdmin] = useState(true);
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("authenticated") === "true" // Check localStorage for authentication
  );
  const [activeIndex,setActiveIndex] = useState(1);
  return (
    <Router> 
      <div className="App"> 
        <div className="main-content">
          <div className="body-section">
            <div className="d-flex flex-column flex-xl-row w-100">
            {authenticated && <div className="sidebar-section min-vh-0">
                <Sidebar activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
              </div>}
              <Navbar /> 
              <Routes> 
                <Route path="/" element={<Home/>} />
                <Route path="/home/:id" element={<Home />} />
                <Route path="/sendMessages/:id" element={<SendMessages />} />
                <Route path="/inbox/:id" element={<Inbox />} />
                <Route path="/requestMessage/:id" element={<Request />} />
                <Route
                  path="/login"
                  element={<Login setAuthenticated={setAuthenticated} />}
                  />
              </Routes>
            </div>
          </div>
        </div>
      </div> 
    </Router>
  );
}

export default App;
