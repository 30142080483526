import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../../styles/Sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPaperPlane, faInbox, faRightFromBracket, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';
import baseUrl from '../../Config/Config';
import api from '../../Api/api';

const Sidebar = ({ activeIndex, setActiveIndex, setAuthenticated }) => {
    const location = useLocation();
    const [isExpanded, setIsExpanded] = useState(true);
    const [messageCount, setMessageCount] = useState(0);
    const userId = localStorage.getItem('user_id');

    useEffect(() => {
        const fetchMessageCount = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await api.get(`${baseUrl}/api/messages/received-messages/${userId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                if (response.data && response.data.messages) {
                    setMessageCount(response.data.messages.length);
                }
            } catch (error) {
                console.error('Error fetching message count:', error);
            }
        };

        fetchMessageCount();
    }, [userId]);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const navigate = (id) => {
        setActiveIndex(id);
    };

    const handleLogout = () => {
        try {
            // Remove token and user_id from localStorage
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            localStorage.removeItem('authenticated')
            window.close()
            
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    return (
        <div className="sid_nav_main-2">
            <button className="expand_button" onClick={toggleExpand}>
                {!isExpanded ? `☰` : ` X`}
            </button>
            <div className={`sidebar visible ${isExpanded ? 'expanded-1' : 'collapsed'}`}>
                <div  className='mt-5 side-bar-contents'>
                    <Link to={`/home/${userId}`}>
                        <FontAwesomeIcon 
                            data-tooltip-id="tool-tip-nav1" 
                            data-tooltip-content="Home" 
                            className={location.pathname === `/home/${userId}` ? "font-icons active" : "font-icons"} 
                            icon={faHome} 
                            onClick={() => navigate(1)} 
                        />
                        <Tooltip id="tool-tip-nav1" className="nav-tooltip" border="1px solid #5e37f6" />
                    </Link>
                    
                    <Link to={`/sendMessages/${userId}`}>
                        <FontAwesomeIcon 
                            data-tooltip-id="tool-tip-nav2" 
                            data-tooltip-content="Send" 
                            className={location.pathname === `/sendMessages/${userId}` ? "font-icons active" : "font-icons"} 
                            icon={faPaperPlane} 
                            onClick={() => navigate(2)} 
                        />
                        <Tooltip id="tool-tip-nav2" className="nav-tooltip" border="1px solid #5e37f6" />
                    </Link>
                    <Link to={`/requestMessage/${userId}`}>
                        <FontAwesomeIcon 
                            data-tooltip-id="tool-tip-nav-request" 
                            data-tooltip-content="Request" 
                            className={location.pathname === `/requestMessage/${userId}` ? "font-icons active" : "font-icons"} 
                            icon={faCommentDots} 
                            onClick={() => navigate(3)} 
                        />
                        <Tooltip id="tool-tip-nav-request" className="nav-tooltip" border="1px solid #5e37f6" />
                    </Link>
                    <div className="logout">
                        <Link to="/login" onClick={handleLogout}>
                            <FontAwesomeIcon 
                                data-tooltip-id="tool-tip-nav-logout" 
                                data-tooltip-content="Logout" 
                                className='font-icons fa-light' 
                                icon={faRightFromBracket} 
                            />
                            <Tooltip id="tool-tip-nav-logout" className="nav-tooltip" border="1px solid #5e37f6" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
