import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import baseUrl from '../../Config/Config';
import { jwtDecode } from 'jwt-decode';
import api from '../../Api/api';

const Login = ({ setAuthenticated }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
  
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
      employeeId: '',
      password: '',
    });
    const [popup, setPopup] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        if (!token) {
          console.error("No token found in URL");
          return; // Exit early if no token
        }

        try {
          const decodedToken = jwtDecode(token);
          const { employeeId } = decodedToken;

          console.log("Decoded Employee ID: ", employeeId);

          const response = await api.post(`${baseUrl}/api/login`, { employeeId: Number(employeeId) });
          console.log(response);

          if (response.data.message === 'User successfully logged in') {
            localStorage.setItem('token', token);
            setPopup({ type: 'success', message: response.data.message });
            setFormData({ employeeId: '' });
            setAuthenticated(true);
            localStorage.setItem('authenticated', true);
            localStorage.setItem("user_id", employeeId);
            navigate(`/home/${employeeId}`);
          } else {
            setPopup({ type: 'error', message: 'User not found. Please check the Employee ID and Password.' });
          }
        } catch (error) {
          console.error('Error logging in:', error);
          setPopup({ type: 'error', message: 'Invalid token or other error. Please try again.' });
        }
      };

      fetchData();
    }, [token, navigate, setAuthenticated]);

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const handleLogin = async (e) => {
      e.preventDefault();

      if (Object.values(formData).some((value) => !value.trim())) {
        setPopup({ type: 'error', message: 'All fields must be filled out' });
        return;
      }

      try {
        const response = await api.post(`${baseUrl}/login`, { employeeId: formData.employeeId, password: formData.password });
        console.log(response);

        if (response.data.message === 'User successfully logged in') {
          localStorage.setItem('token', response.data.accessToken);
          setPopup({ type: 'success', message: response.data.message });
          setFormData({ employeeId: '' });
          setAuthenticated(true);
          navigate(`/home/${formData.employeeId}`);
        } else {
          setPopup({ type: 'error', message: 'User not found. Please check the Employee ID and Password.' });
        }
      } catch (error) {
        console.error('Error logging in:', error);
        setPopup({ type: 'error', message: 'Something went wrong. Please try again.' });
      }
    };

    const closePopup = () => {
      setPopup(null);
    };

    return (
      <div className='loading-sec center'>
        <div className='loading-image'>
          <img src="/images/bouncing-circles.svg" alt="Loading" className='loading-image-item' />
        </div>
        <div className='loading-text'>Loading</div>
       
      </div>
    );
};

export default Login;
